import "./product-modal.scss"

import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"

import IcoProductCartConfirmation from "assets/images/ico-product-cart-confirmation.svg"

const ProductCartConfirmation = ({ setCartConfirmation }) => {
  return (
    <div className="product-modal">
      <div className="product-modal__wrapper">
        <PageSubHeader title="Produkt dodano do koszyka" />
        <button
          className="product-modal__close"
          onClick={() => setCartConfirmation(false)}
        >
          Close
        </button>
        <div className="product-modal__confirmation">
          <img src={IcoProductCartConfirmation} alt="" />
          <h4>Wybrany produkt został dodany do Twojego koszyka.</h4>
          <p>Chcesz wybrać kolejny produkt?</p>
          <div className="product-modal__row">
            <Button onClick={() => setCartConfirmation(false)}>
              Kontynuuj zakupy
            </Button>
            <Button type="link" to="/koszyk/" className="button--bordered">
              Przejdź do koszyka
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCartConfirmation
